:root {
  --hex-border-tb: 30px;
  --hex-height: 60px;
  --hex-border-lr: 52px;
  --hex-width: 104px;
  --hex-factor: 1;

  --hex-rotate-border-tb: 30px;
  --hex-rotate-height: 60px;
  --hex-rotate-border-lr: 52px;
  --hex-rotate-width: 104px;
  --hex-rotate-factor: 1.5;

  --hexagon-image-width: 69.5px;
  --hexagon-image-height: 60px;
  --hexagon-image-factor: 1.4;

  --hex-bg-factor: 2;
}
::placeholder {
  color: #fff !important;
  opacity: 0.6 !important;
  font-family: "EB Garamond";
}
.hex-bg-row-2 {
  transform: translate(calc(var(--hexagon-image-width)*var(--hex-bg-factor)/2), 0px) !important;
}
/* .home-page {
  background-color: hsla(29, 21%, 26%, 1) !important;
} */
.home-page {
  background-color: white !important;
}
.home-page:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 55vh;
  background: rgba(200, 225, 100, 0.9) !important;
  transform: skew(0deg, -10deg);
  transform-origin: left;
  z-index: 10;
}

.text-decoration-none{
  text-decoration: none !important;
}

@media (min-width: 1200px) {
  .home-page:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 800px;
    height: 100vh;
    background: rgba(200, 225, 100, 0.8) !important;
    transform: skew(-15deg);
    transform-origin:top;
    z-index: 10;
  }
}
.home-page .clients-we-have-worked-with {
  background-color: white;
}
.home-page .aboutus-section {
  background-color: white;
}
.home-page .area-of-specialization {
  background-color: white;
}
.aboutus-page {
  background-color: #dfd3be;
}
.aboutus-page .certificate-section {
  background-color: white;
}
.contact-page {
  background-color: hsla(29, 21%, 26%, 1) !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
  overflow-y: visible !important;
}
p {
  margin-bottom: 0px !important;
}
.object-fit-fill {
  object-fit: fill;
}
.justify-content-space-evenly {
  justify-content: space-evenly !important;
}
.cursor-pointer {
  cursor: pointer !important;
}

.home-background {
  background: hsla(29, 21%, 26%, 1) !important;
}
.white-background {
  background-color: transparent !important;
}
.blue-background {
  background-color: #3782c3 !important;
}
.aboutus-background {
  background-color: #dfd3be;
}
.careers-background {
  background-color: #dfd3be;
}
.first-section {
  /* margin-top: -12vh !important; */
  margin-top: 0 !important;
  min-height: 600px;
}
.h-35vh {
  height: 35vh;
}
.h-50vh {
  height: 50vh;
}
.h-90vh {
  height: 90vh;
}
.h-100vh {
  height: 100vh;
}
.min-h-100vh {
  min-height: 100vh;
}
.h-120vh {
  height: 120vh;
}
.h-150vh {
  height: 150vh;
}
.h-200vh {
  height: 200vh;
}
.w-50 {
  width: 50%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-60 {
  width: 60%;
}
.w-120 {
  width: 120%;
}
.navbar-custom {
  background: #ffffff;
  border-bottom: 0.5px solid #dadada;
  position: sticky !important;
  top: 2vh;
  z-index: 999;
}

.fc-dfd3be {
  color: #dfd3be;
}
/* F O N T S */
.f-nav-title {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #000000;
}
.f-project-info-title {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.4px;
  color: #000000;
}
.f-nav-link {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #1f1914;
  padding-left: 0px !important;
  padding-right: 1rem !important;
}
.f-home-title {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
}
.f-home-p {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.15px;
}
.max-h-100vh {
  max-height: 100vh;
}
.about-us-image img {
  height: 40vh;
}
.aboutus-certificate {
  width: 90%;
}
.width-custom {
  width: 100%;
}
.f-contact-heading {
  font-family: "EB Garamond" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  letter-spacing: 0.15px !important;
  color: #524335 !important;
}
.mission-image {
  height: 20vh;
}
.f-contact-sub-heading {
  font-family: "EB Garamond" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 150% !important;
  letter-spacing: 0.15px !important;
  color: #524335 !important;
}
.f-contact-p {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #1f1914;
}
.f-project-title {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: -0.5px;
  color: #000000;
}
.f-background-title {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 140%;
  color: #dfd3be;
  opacity: 0.15;
}
.f-background-about {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 123.333px;
  line-height: 140%;
  color: #e6e6e6;
  opacity: 0.85;
  overflow: hidden;
}
.f-footer-body-1 {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.52%;
  letter-spacing: 0.09em;
}
.f-footer-body-2 {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.52%;
  letter-spacing: 0.09em;
}
.footer {
  height: 80vh;
}
.footer-content {
  width: 80%;
  height: 50vh;
}
.f-footer-link {
  text-decoration: none;
  color: black;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 30px */

  letter-spacing: 0.15px;
}
.f-h1 {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 130%;
  letter-spacing: -1.5px;
  color: #000000;
}
.footer-link-border {
  border-right: 1px solid black;
  height: 15px;
  align-self: center;
}
.f-get-in-touch {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 130%;
  /* or 166px */
  letter-spacing: 10px;

  color: #ffffff;

  opacity: 0.2;
}
.aboutus-contact {
  height: 750px;
}
.f-get-in-touch.in-home {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: 10px;
  color: #ffffff;
  opacity: 0.5;
}
.f-home-contact-button {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 145.52%;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  color: #1f1914;
}
.f-home-view-projects-button {
  font-family: "EB Garamond";
  font-style: normal;
  height: 36px;
  font-weight: 600;
  font-size: 14px;
  line-height: 145.52%;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  color: #dfd3be;
  background-color: transparent;
  border: none;
}
.f-h1-highlight {
  width: 100%;
  text-align: center;
  transform: translate(0%, 50%);
  bottom: 50%;
}
/* ****************************** */
/* ****************************** */
.home-hexagon-images {
  width: 100%;
  margin-top: 10px !important;
}
.clients-we-have-worked-with {
  width: 100%;
  min-height: 760px;
}
.clients-we-have-worked-with .clients {
  transform: rotate(90deg);
}
.contact-box {
  border: 1px solid black;
  width: 40vw !important;
}
.click-to-view {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  /* identical to box height, or 20px */

  letter-spacing: 1.25px;
  text-transform: uppercase;

  /* Text */

  color: #1f1914;
}
.contact-box-blue {
  background-color: #3782c3;
  min-width: 40% !important;
}

.mission-text {
  bottom: 20vh;
  right: 0;
  z-index: 0;
}
.about-text {
  top: 0%;
  right: 0%;
  z-index: 0;
}
.footer-links {
  width: 100%;
}
.footer-logo-heading {
  width: 80%;
}
.footer-logo-heading-content {
  display: none;
}
.footer-social {
  width: 80%;
}
.mission-image {
  z-index: 1;
}
.founding-members-desktop {
  display: none !important;
}
.founding-members-mobile{
  display: block !important;
}
.w-40 {
  width: 40% !important;
}
.send-message-button {
  background: #ffffff !important;
  box-shadow: 2px 2px 20px rgba(31, 25, 20, 0.6);
  width: 100%;
  border-radius: 0 !important;
  padding: 1rem !important;
  font-family: "EB Garamond" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 140% !important;
  letter-spacing: 1.25px !important;
  text-transform: uppercase !important;
  color: #1f1914 !important;
  border-color: white !important;
}
.pt-8 {
  padding-top: 8vh !important;
}
.get-in-touch-container {
  transform: rotate(0deg);
  width: 100%;
}
.or-image-container {
  height: 160px;
}
.or-image-container img {
  width: 160px;
  right: 20%;
}
.home-contact-form {
  width: 100%;
}
.home-container {
  /* margin-bottom: 17vh !important; */
  /* margin-top: 11vh !important; */
  height: 50vh !important;
}
.home-contact-us-button {
  width: 100%;
  height: 36px;
  background: #dfd3be;
  box-shadow: 2px 2px 20px rgba(31, 25, 20, 0.6);
}
.home-contact-us-button:hover {
  /* padding: 18px 80px; */
  background: #fff;
  color: black;
}
.home-view-projects-button {
}
.home-view-projects-button:hover {
  color: white;
}

.hex-row-1 {
}

.hex {
  float: left;
  margin-left: 2vw;
  /* margin-bottom: -26px; */
}
.hex .top {
  width: 0;
  border-bottom: calc(var(--hex-border-tb) * var(--hex-factor)) solid #1f1914;
  border-left: calc(var(--hex-border-lr) * var(--hex-factor)) solid transparent;
  border-right: calc(var(--hex-border-lr) * var(--hex-factor)) solid transparent;
}
.hex .middle {
  width: calc(var(--hex-width) * var(--hex-factor));
  height: calc(var(--hex-height) * var(--hex-factor));
  background: #1f1914;
}
.hex .bottom {
  width: 0;
  border-top: calc(var(--hex-border-tb) * var(--hex-factor)) solid #1f1914;
  border-left: calc(var(--hex-border-lr) * var(--hex-factor)) solid transparent;
  border-right: calc(var(--hex-border-lr) * var(--hex-factor)) solid transparent;
}

.hex-rotate-rows:nth-child(odd) {
  padding-top: calc(var(--hex-roate-width) / 2);
}
.hex-rotate-col-1 {
  margin-top: 25vh;
}

.hex-rotate {
  float: left;
  /* margin-left: 90px; */
  /* margin-bottom: -26px; */
  transform: rotate(90deg);
}
.home-small-hex {
  transform: translate(-90%, 80%);
  height: 110px;
}
.home-large-hexagon {
  height: 250px;
}
.hex-rotate .top {
  width: 0;
  border-bottom: calc(var(--hex-rotate-border-tb) * var(--hex-rotate-factor))
    solid rgba(201, 226, 101, 0.5);
  border-left: calc(var(--hex-rotate-border-lr) * var(--hex-rotate-factor))
    solid transparent;
  border-right: calc(var(--hex-rotate-border-lr) * var(--hex-rotate-factor))
    solid transparent;
}
.hex-rotate .middle {
  width: calc(var(--hex-rotate-width) * var(--hex-rotate-factor));
  height: calc(var(--hex-rotate-height) * var(--hex-rotate-factor));
  background: rgba(201, 226, 101, 0.5);
}
.hex-rotate .bottom {
  width: 0;
  border-top: calc(var(--hex-rotate-border-tb) * var(--hex-rotate-factor)) solid
    rgba(201, 226, 101, 0.5);
  border-left: calc(var(--hex-rotate-border-lr) * var(--hex-rotate-factor))
    solid transparent;
  border-right: calc(var(--hex-rotate-border-lr) * var(--hex-rotate-factor))
    solid transparent;
}
.hex-rotate-h1 {
  position: absolute;
  bottom: 50%;
  transform: translate(0%, 50%);
  left: 0;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  width: 100%;
}
.hex-rotate-p {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  text-align: center;
}
.area-of-specialization {
  height: calc(var(--hex-roate-width) * 10);
}
/* ************************************* */
.hex-rotate-animate {
  float: left;
  /* margin-left: 90px; */
  /* margin-bottom: -26px; */
  transform: rotate(90deg);
  transition: transform 0.7s ease-out;
}
.hex-rotate-animate:hover {
  transform: rotate(450deg);
}
.hex-rotate-animate:hover .top {
  border-bottom: calc(var(--hex-rotate-border-tb) * var(--hex-rotate-factor))
    solid #dfd3be;
}
.hex-rotate-animate:hover .middle {
  background: #dfd3be;
}
.hex-rotate-animate:hover .bottom {
  border-top: calc(var(--hex-rotate-border-tb) * var(--hex-rotate-factor)) solid
    #dfd3be;
}
.hex-rotate-animate .top {
  width: 0;
  border-bottom: calc(var(--hex-rotate-border-tb) * var(--hex-rotate-factor))
    solid rgba(201, 226, 101, 1);
  border-left: calc(var(--hex-rotate-border-lr) * var(--hex-rotate-factor))
    solid transparent;
  border-right: calc(var(--hex-rotate-border-lr) * var(--hex-rotate-factor))
    solid transparent;
  transition: border-bottom 0.7s ease-out;
}
.hex-rotate-animate .middle {
  width: calc(var(--hex-rotate-width) * var(--hex-rotate-factor));
  height: calc(var(--hex-rotate-height) * var(--hex-rotate-factor));
  background: rgba(201, 226, 101, 1);
  transition: background 0.7s ease-out;
}
.hex-rotate-animate .bottom {
  width: 0;
  border-top: calc(var(--hex-rotate-border-tb) * var(--hex-rotate-factor)) solid
    rgba(201, 226, 101, 1);
  border-left: calc(var(--hex-rotate-border-lr) * var(--hex-rotate-factor))
    solid transparent;
  border-right: calc(var(--hex-rotate-border-lr) * var(--hex-rotate-factor))
    solid transparent;
  transition: border-top 0.7s ease-out;
}
.hex-rotate-animate-h1 {
  position: absolute;
  bottom: 10%;
  left: 0;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: left;
  width: 100%;
  /* transform: rotate(90deg); */
}
.hex-rotate-animate-container {
  width: fit-content;
}
/* ************************************* */
.hex-bg-row-1 {
  /* transform: translate(calc(var(--hex-height)/2), 118px) !important; */
  transform: translate(0px, calc(var(--hexagon-image-height))) !important;    z-index: 0;
}
.hex-bg-row-2 {
  transform: translate(calc(var(--hexagon-image-width)*var(--hex-bg-factor)/2), calc(var(--hexagon-image-height)/2)) !important;
}
.hex-bg-row-3 {
  /* transform: translate(94px, 170px) !important; */
}
.hex-bg-div{
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 51% 100%, 1% 75%, 0 25%);
  width: calc(var(--hexagon-image-height) * var(--hex-bg-factor));
  height: calc(var(--hexagon-image-width) * var(--hex-bg-factor));
  margin-left: calc(var(--hexagon-image-width)/3);
  background-color: rgba(223, 211, 190, 0.05);;

}
.hex-bg {
  float: left;
  margin-left: 2vw;
  margin-bottom: 26px;
}
.hex-bg .top {
  width: 0;
  border-bottom: calc(var(--hex-border-tb) * var(--hex-bg-factor)) solid
    rgba(223, 211, 190, 0.05);
  border-left: calc(var(--hex-border-lr) * var(--hex-bg-factor)) solid transparent;
  border-right: calc(var(--hex-border-lr) * var(--hex-bg-factor)) solid transparent;
}
.hex-bg .middle {
  width: calc(var(--hex-width) * var(--hex-bg-factor));
  height: calc(var(--hex-height) * var(--hex-bg-factor));
  background: rgba(223, 211, 190, 0.05);
}
.hex-bg .bottom {
  width: 0;
  border-top: calc(var(--hex-border-tb) * var(--hex-bg-factor)) solid
    rgba(223, 211, 190, 0.05);
  border-left: calc(var(--hex-border-lr) * var(--hex-bg-factor)) solid transparent;
  border-right: calc(var(--hex-border-lr) * var(--hex-factor)) solid transparent;
}
/* ***************************************** */
.founding-member-container {
  max-width: 1366px;
  margin-left: 8vw;
  /* clip-path: polygon(15% 0, 85% 0, 100% 15%, 100% 85%, 85% 100%, 15% 100%, 0 85%, 0 20%); */
}
.founding-member-name {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
}
.founding-member-title {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
.founding-member-bio {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.5px;
  color: #000000;
}
.founding-member-info-container {
  display: inline-block;
  position: absolute;
  /* width: 70vw; */
  max-width: 992px;
  height: 280px;
  background-color: rgba(223, 211, 190, 0.2);
  z-index: 1;
  transform: translate(-15%);
  clip-path: polygon(0 0, 95% 0, 100% 20%, 100% 80%, 95% 100%, 0 100%);
}
.founding-member-info {
  /* padding-left: 10rem; */
  /* padding-top: 1rem; */
  padding-right: 1rem;
  height: 100%;
}
.hexagon-image {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  height: calc(var(--hexagon-image-height) * var(--hexagon-image-factor));
  width: calc(var(--hexagon-image-width) * var(--hexagon-image-factor));
  display: inline-block;
  position: relative;
  z-index: 2;
}
.teams-section {
  height: 100vh;
}
.hexagon-image-team {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  width: calc(var(--hexagon-image-width) * var(--hexagon-image-factor));
  height: calc(var(--hexagon-image-height) * var(--hexagon-image-factor));
  display: inline-block;
  position: relative;
  z-index: 2;
}
.hexagon-image-client {
  -webkit-clip-path: polygon(
    25% 0%,
    75% 0%,
    100% 50%,
    75% 100%,
    25% 100%,
    0% 50%
  );
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  width: calc(var(--hexagon-image-width) * var(--hexagon-image-factor));
  height: calc(var(--hexagon-image-height) * var(--hexagon-image-factor));
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  background-color: #dfd3be;
  margin-top: 3vw;
  transform: rotate(-90deg);
}
.hexagon-image-client img {
  width: calc(var(--hexagon-image-width) * var(--hexagon-image-factor));
  object-fit: scale-down;
  max-width: 90%;
}
.hexagon-image-team-info {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  display: flex;
  justify-content: center;
  align-items: center;
}
.hexagon-image img {
  width: 100%;
}

.border-bottom-nav {
  border-bottom: 4px solid #524335;
  width: 50%;
}
.project-list-item {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  letter-spacing: 0.5px;

  /* Text */
  color: #1f1914;
  gap: 16px;
  transition: font-size 0.1s ease-out, font-weight 0.1s ease-out;
}
.breadcrumb-projectinfo ol {
  margin-bottom: 0;
}
.breadcrumb-projectinfo ol li a {
  text-decoration: none;
  color: black;
  font-size: 14px;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">") !important ;
  font-size: 14px;
}
.project-list-item a {
  text-decoration: none !important;
  color: #1f1914;
}
.project-list-item a:hover {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  letter-spacing: 0.15px;

  /* Text */

  color: #1f1914;
}
.blog-card {
  width: 500px;
  height: 500px;
}
.blog-card a {
  text-decoration: none !important;
  color: #1f1914;
}
.blog-card a:hover {
  width: 550px;
  height: 550px;
}
