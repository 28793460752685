
.home-contact-us-button {
  /* padding: 18px 80px; */
  gap: 8px;
  width: 160px;
  height: 50px;
  background: #dfd3be;
  box-shadow: 2px 2px 20px rgba(31, 25, 20, 0.6);
}
.image-title {
  font-family: "EB Garamond";
  font-style: normal;
  font-size: 14px;
  line-height: 100%;
}

.f-home-p{
  font-size: 16px;
}

.aboutus-contact {
  min-height: 150vh;
}

@media (min-width: 330px) {
  .home-contact-us-button {
    /* padding: 18px 80px; */
    gap: 8px;
    width: 160px;
    height: 50px;
    background: #dfd3be;
    box-shadow: 2px 2px 20px rgba(31, 25, 20, 0.6);
  }
  .image-title {
    font-family: "EB Garamond";
    font-style: normal;
    font-size: 14px;
    line-height: 100%;
  }
}
@media (min-width: 576px) {
  :root {
    --hexagon-image-factor: 2;
    --hex-bg-factor: 3;
  }
  .about-us-image img {
    height: 50vh;
  }
  .f-home-title {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 140%;
  }
  .image-title {
    font-family: "EB Garamond";
    font-style: normal;
    font-size: 16px;
    line-height: 100%;
  }
  .f-home-p {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
  .f-footer-link {
    text-decoration: none;
    color: black;
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
  .aboutus-contact {
    min-height: 150vh;
  }
  .clients-we-have-worked-with {
    width: 100%;
    min-height: 960px;
  }
  .home-contact-us-button {
    /* padding: 18px 80px; */
    gap: 8px;
    width: 200px;
    height: 50px;
    background: #dfd3be;
    box-shadow: 2px 2px 20px rgba(31, 25, 20, 0.6);
  }
}

@media (min-width: 768px) {
  :root {
    --hexagon-image-factor: 2.2;
    --hex-bg-factor: 2.2;
  }
  .f-footer-link {
    text-decoration: none;
    color: black;
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
  .about-us-image img {
    height: 80vh;
  }
  .f-home-title {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 140%;
  }
  .image-title {
    font-family: "EB Garamond";
    font-style: normal;
    font-size: 18px;
    line-height: 100%;
  }
  .f-home-p {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
  .get-in-touch-container {
    transform: rotate(-90deg);
    width: 40%;
  }
  .home-contact-form {
    width: 40%;
  }
  .f-get-in-touch {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 400;
    font-size: 84px;
    line-height: 130%;
    /* or 166px */
    letter-spacing: 10px;

    color: #ffffff;

    opacity: 0.2;
  }
  .aboutus-contact {
    height: 700px;
  }
  .hex-bg-row-1 {
    /* transform: translate(calc(var(--hex-height)/2), 118px) !important; */
    transform: translate(0px, calc(var(--hexagon-image-height))) !important;
    z-index: 0;
  }
  .hex-bg-row-2 {
    transform: translate(
      calc(var(--hexagon-image-width) * var(--hex-bg-factor) / 2),
      calc(var(--hexagon-image-height) / 2)
    ) !important;
  }
  .hex-bg-row-3 {
    /* transform: translate(94px, 170px) !important; */
  }
  .f-get-in-touch.in-home {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 400;
    font-size: 78px;
    line-height: 130%;
    letter-spacing: 10px;
    color: #ffffff;
    opacity: 0.5;
  }
  
}

@media (min-width: 992px) {
  :root {
    --hex-border-tb: 60px;
    --hex-height: 120px;
    --hex-border-lr: 104px;
    --hex-width: 208px;
    --hex-factor: 1;

    --hex-bg-factor: 3;

    --hex-rotate-border-tb: 60px;
    --hex-rotate-height: 120px;
    --hex-rotate-border-lr: 104px;
    --hex-rotate-width: 208px;
    --hex-rotate-factor: 2;

    --hexagon-image-factor: 3;
    --hexagon-image-founder-factor: 4;
  }
  .clients-we-have-worked-with {
    width: 100%;
    min-height: 1560px;
  }
  .founding-members-desktop {
    display: block !important;
  }
  .founding-members-mobile {
    display: none !important;
  }
  .founding-member-name {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    color: #000000;
  }
  .founding-member-title {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
  }
  .founding-member-bio {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #000000;
  }
  .founding-member-info-container {
    display: inline-block;
    position: absolute;
    /* width: 70vw; */
    max-width: 992px;
    height: calc(
      var(--hexagon-image-height) * var(--hexagon-image-founder-factor)
    );
    background-color: rgba(223, 211, 190, 0.2);
    z-index: 1;
    transform: translate(-15%);
    clip-path: polygon(0 0, 95% 0, 100% 20%, 100% 80%, 95% 100%, 0 100%);
  }
  .founding-member-info {
    padding-left: 10em;
    padding-top: 1rem;
    padding-right: 1rem;
  }
  .hexagon-image-client {
    -webkit-clip-path: polygon(
      50% 0%,
      100% 25%,
      100% 75%,
      50% 100%,
      0% 75%,
      0% 25%
    );
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    height: calc(var(--hexagon-image-width) * var(--hexagon-image-factor));
    width: calc(var(--hexagon-image-height) * var(--hexagon-image-factor));
    position: relative;
    z-index: 2;
    background-color: #dfd3be;
    margin-left: 3vw;
    margin-top: 0;
    transform: rotate(0);
  }
  .home-hexagon-images {
    margin-top: -2vh !important;
  }
  .hexagon-image {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    height: calc(var(--hexagon-image-height) * var(--hexagon-image-factor));
    width: calc(var(--hexagon-image-width) * var(--hexagon-image-factor));
    display: inline-block;
    position: relative;
    z-index: 2;
  }
  .hexagon-image-founder {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    height: calc(
      var(--hexagon-image-height) * var(--hexagon-image-founder-factor)
    );
    width: calc(
      var(--hexagon-image-width) * var(--hexagon-image-founder-factor)
    );
    display: inline-block;
    position: relative;
    z-index: 2;
  }
  .f-background-about {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 231.333px;
    line-height: 140%;
    color: #e6e6e6;
    opacity: 0.85;
    overflow: hidden;
  }
  .breadcrumb-projectinfo ol li a {
    text-decoration: none;
    color: black;
    font-size: 26px;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, ">") !important ;
    font-size: 26px;
  }
  .width-custom {
    width: 70%;
  }
  .teams-section {
    height: 100vh;
  }
  .about-us-image img {
    height: 70vh;
  }
  .aboutus-certificate {
    width: 48%;
  }
  .f-nav-title {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
  }
  .f-project-info-title {
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.4px;
  }
  .f-nav-link {
    font-size: 16px;
    line-height: 21px;
    padding-left: 0px !important;
    padding-right: 1rem !important;
  }
  .f-home-title {
    font-size: 48px;
    line-height: 140%;
  }
  .image-title {
    font-family: "EB Garamond";
    font-style: normal;
    font-size: 20px;
    line-height: 100%;
  }
  .f-home-p {
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
  .f-contact-heading {
    font-size: 20px !important;
    line-height: 150% !important;
    letter-spacing: 0.15px !important;
  }
  .f-contact-sub-heading {
    font-size: 12px !important;
    line-height: 150% !important;
    letter-spacing: 0.15px !important;
  }
  .f-contact-p {
    font-size: 24px;
    line-height: 150%;
    width: 70%;
  }
  .f-project-title {
    font-size: 64px;
    line-height: 140%;
    letter-spacing: -0.5px;
  }
  .f-background-title {
    font-weight: 500;
    font-size: 244.45px;
    line-height: 140%;
    opacity: 0.15;
  }
  .f-background-about {
    font-weight: 900;
    font-size: 231.333px;
    line-height: 140%;
  }
  .mission-image {
    height: 35vh;
  }
  .f-background-about {
    font-weight: 900;
    font-size: 231.333px;
    line-height: 140%;
    opacity: 0.85;
    overflow: hidden;
  }
  .f-footer-body-1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 145.52%;
    letter-spacing: 0.09em;
  }
  .f-footer-body-2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 145.52%;
    letter-spacing: 0.09em;
  }
  .f-footer-link {
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
  .f-h1 {
    font-weight: 400;
    font-size: 96px;
    line-height: 130%;
    letter-spacing: -1.5px;
  }
  .footer-link-border {
    border-right: 1px solid black;
    height: 15px;
    align-self: center;
  }
  .get-in-touch-container {
    transform: rotate(-90deg);
    width: 40%;
  }
  .or-image-container {
    height: 320px;
  }
  .f-get-in-touch {
    font-weight: 400;
    font-size: 128px;
    line-height: 130%;
    letter-spacing: 10px;
    opacity: 0.2;
  }
  .footer {
    height: 100vh;
  }
  .aboutus-contact {
    height: 100vh;
  }
  .hexagon-image-team {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    width: calc(var(--hexagon-image-width) * var(--hexagon-image-factor));
    height: calc(var(--hexagon-image-height) * var(--hexagon-image-factor));
    display: inline-block;
    position: relative;
    z-index: 2;
  }
  .footer-link-border {
    border-right: 1px solid black;
    height: 15px;
    align-self: center;
  }
  .f-get-in-touch.in-home {
    font-weight: 400;
    font-size: 128px;
    line-height: 130%;
    letter-spacing: 10px;
    opacity: 0.5;
  }
  .f-home-contact-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 145.52%;
    letter-spacing: 0.09em;
  }
  .footer-content {
    width: 70%;
    height: 35vh;
  }
  .footer-links {
    width: 70%;
  }
  .footer-social {
    width: 100%;
  }
  .footer-logo-heading {
    width: 60%;
  }
  .or-image-container img {
    width: 320px;
    right: 20%;
  }
  .home-contact-form {
    width: 40%;
  }
  .f-home-view-projects-button {
    height: 56px;
    font-weight: 600;
    font-size: 14px;
    line-height: 145.52%;
    letter-spacing: 0.09em;
  }
  .f-h1-highlight {
    width: 100%;
    text-align: center;
    transform: translate(0%, 50%);
    bottom: 50%;
  }
  .hex-rotate-animate-h1 {
    position: absolute;
    bottom: 10%;
    left: 0;
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 140%;
    text-align: left;
    width: 100%;
    /* transform: rotate(90deg); */
  }
  .click-to-view {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    letter-spacing: 1.25px;
    text-transform: uppercase;

    /* Text */

    color: #1f1914;
  }
  .hex-rotate-h1 {
    position: absolute;
    bottom: 50%;
    transform: translate(0%, 50%);
    left: 0;
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 140%;
    text-align: center;
    width: 100%;
  }
  .hex-rotate-p {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    text-align: center;
  }
  .home-contact-us-button {
    /* padding: 18px 80px; */
    gap: 8px;
    width: 262px;
    height: 56px;
    background: #dfd3be;
    box-shadow: 2px 2px 20px rgba(31, 25, 20, 0.6);
  }
  .home-small-hex {
    transform: translate(-80%, 70%);
    height: 180px;
  }
  .home-large-hexagon {
    height: 370px;
    width: fit-content;
  }
  .first-section {
    /* margin-top: -9vh !important; */
    min-height: 90vh;
  }
  .clients-we-have-worked-with {
    /* height: 170vh; */
  }
  .clients-we-have-worked-with .clients {
    transform: rotate(0deg);
  }
}

@media (min-width: 1366px) {
  :root {
    --hex-border-tb: 60px;
    --hex-height: 120px;
    --hex-border-lr: 104px;
    --hex-width: 208px;
    --hex-factor: 1;

    --hex-rotate-border-tb: 60px;
    --hex-rotate-height: 120px;
    --hex-rotate-border-lr: 104px;
    --hex-rotate-width: 208px;
    --hex-rotate-factor: 2;

    --hexagon-image-factor: 4;
  }
  .founding-members-desktop {
    display: block !important;
  }
  .founding-members-mobile {
    display: none !important;
  }
  .founding-member-name {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 140%;
    color: #000000;
  }
  .founding-member-title {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    color: #000000;
  }
  .founding-member-bio {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #000000;
  }
  .founding-member-info-container {
    display: inline-block;
    position: absolute;
    /* width: 70vw; */
    max-width: 992px;
    height: calc(
      var(--hexagon-image-height) * var(--hexagon-image-founder-factor)
    );
    background-color: rgba(192, 192, 192, 0.6);
    z-index: 1;
    transform: translate(-15%);
    clip-path: polygon(0 0, 95% 0, 100% 20%, 100% 80%, 95% 100%, 0 100%);
  }
  .founding-member-info {
    padding-left: 10em;
    padding-top: 1rem;
    padding-right: 1rem;
  }
  .hexagon-image-client {
    -webkit-clip-path: polygon(
      50% 0%,
      100% 25%,
      100% 75%,
      50% 100%,
      0% 75%,
      0% 25%
    );
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    height: calc(var(--hexagon-image-width) * var(--hexagon-image-factor));
    width: calc(var(--hexagon-image-height) * var(--hexagon-image-factor));
    position: relative;
    z-index: 2;
    background-color: #dfd3be;
    margin-left: 3vw;
    margin-top: 0;
    transform: rotate(0);
  }
  .home-hexagon-images {
    margin-top: -2vh !important;
  }
  .hexagon-image {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    height: calc(var(--hexagon-image-height) * var(--hexagon-image-factor));
    width: calc(var(--hexagon-image-width) * var(--hexagon-image-factor));
    display: inline-block;
    position: relative;
    z-index: 2;
  }
  .f-background-about {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 231.333px;
    line-height: 140%;
    color: #e6e6e6;
    opacity: 0.85;
    overflow: hidden;
  }
  .breadcrumb-projectinfo ol li a {
    text-decoration: none;
    color: black;
    font-size: 26px;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, ">") !important ;
    font-size: 26px;
  }
  .width-custom {
    width: 70%;
  }
  .teams-section {
    height: 150vh;
  }
  .about-us-image img {
    height: 70vh;
  }
  .aboutus-certificate {
    width: 48%;
  }
  .f-nav-title {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
  }
  .f-project-info-title {
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.4px;
  }
  .f-nav-link {
    font-size: 16px;
    line-height: 21px;
    padding-left: 0px !important;
    padding-right: 1rem !important;
  }
  .f-home-title {
    font-size: 48px;
    line-height: 140%;
  }
  .image-title {
    font-family: "EB Garamond";
    font-style: normal;
    font-size: 20px;
    line-height: 100%;
  }
  .f-home-p {
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
  .f-contact-heading {
    font-size: 20px !important;
    line-height: 150% !important;
    letter-spacing: 0.15px !important;
  }
  .f-contact-sub-heading {
    font-size: 12px !important;
    line-height: 150% !important;
    letter-spacing: 0.15px !important;
  }
  .f-contact-p {
    font-size: 24px;
    line-height: 150%;
    width: 70%;
  }
  .f-project-title {
    font-size: 64px;
    line-height: 140%;
    letter-spacing: -0.5px;
  }
  .f-background-title {
    font-weight: 500;
    font-size: 244.45px;
    line-height: 140%;
    opacity: 0.15;
  }
  .f-background-about {
    font-weight: 900;
    font-size: 231.333px;
    line-height: 140%;
  }
  .mission-image {
    height: 35vh;
  }
  .f-background-about {
    font-weight: 900;
    font-size: 231.333px;
    line-height: 140%;
    opacity: 0.85;
    overflow: hidden;
  }
  .f-footer-body-1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 145.52%;
    letter-spacing: 0.09em;
  }
  .f-footer-body-2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 145.52%;
    letter-spacing: 0.09em;
  }
  .f-footer-link {
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
  .f-h1 {
    font-weight: 400;
    font-size: 96px;
    line-height: 130%;
    letter-spacing: -1.5px;
  }
  .footer-link-border {
    border-right: 1px solid black;
    height: 15px;
    align-self: center;
  }
  .get-in-touch-container {
    transform: rotate(-90deg);
    width: 40%;
  }
  .or-image-container {
    height: 320px;
  }
  .f-get-in-touch {
    font-weight: 400;
    font-size: 128px;
    line-height: 130%;
    letter-spacing: 10px;
    opacity: 0.2;
  }
  .footer {
    height: 120vh;
  }
  .aboutus-contact {
    height: 100vh;
  }
  .hexagon-image-team {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    width: calc(var(--hexagon-image-width) * var(--hexagon-image-factor));
    height: calc(var(--hexagon-image-height) * var(--hexagon-image-factor));
    display: inline-block;
    position: relative;
    z-index: 2;
  }
  .footer-link-border {
    border-right: 1px solid black;
    height: 15px;
    align-self: center;
  }
  .f-get-in-touch.in-home {
    font-weight: 400;
    font-size: 128px;
    line-height: 130%;
    letter-spacing: 10px;
    opacity: 0.5;
  }
  .f-home-contact-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 145.52%;
    letter-spacing: 0.09em;
  }
  .footer-content {
    width: 70%;
    height: 35vh;
  }
  .footer-links {
    width: 70%;
  }
  .footer-social {
    width: 100%;
  }
  .footer-logo-heading {
    width: 60%;
  }
  .or-image-container img {
    width: 320px;
    right: 20%;
  }
  .home-contact-form {
    width: 40%;
  }
  .f-home-view-projects-button {
    height: 56px;
    font-weight: 600;
    font-size: 14px;
    line-height: 145.52%;
    letter-spacing: 0.09em;
  }
  .f-h1-highlight {
    width: 100%;
    text-align: center;
    transform: translate(0%, 50%);
    bottom: 50%;
  }
  .hex-rotate-animate-h1 {
    position: absolute;
    bottom: 10%;
    left: 0;
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 140%;
    text-align: left;
    width: 100%;
    /* transform: rotate(90deg); */
  }
  .click-to-view {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    letter-spacing: 1.25px;
    text-transform: uppercase;

    /* Text */

    color: #1f1914;
  }
  .hex-rotate-h1 {
    position: absolute;
    bottom: 50%;
    transform: translate(0%, 50%);
    left: 0;
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 140%;
    text-align: center;
    width: 100%;
  }
  .hex-rotate-p {
    font-family: "EB Garamond";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    text-align: center;
  }
  .home-contact-us-button {
    /* padding: 18px 80px; */
    gap: 8px;
    width: 262px;
    height: 56px;
    background: #dfd3be;
    box-shadow: 2px 2px 20px rgba(31, 25, 20, 0.6);
  }
  .home-small-hex {
    transform: translate(-80%, 70%);
    height: 250px;
  }
  .home-large-hexagon {
    height: 500px;
    width: fit-content;
  }
  .first-section {
    /* margin-top: -9vh !important; */
    min-height: 90vh;
  }
  .clients-we-have-worked-with {
    /* height: 170vh; */
  }
  .clients-we-have-worked-with .clients {
    transform: rotate(0deg);
  }
}
@media (min-width: 1920px) {
}
